define('mvp-web/routes/dashboard', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service,
      Route = Ember.Route;
  exports.default = Route.extend(_authenticatedRouteMixin.default, {
    globalLoader: service(),

    activate: function activate() {
      this._super.apply(this, arguments);

      this.get('globalLoader.initialize').perform(this);
    }
  });
});